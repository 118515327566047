@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.font-amazon-ember {
  font-family: 'Amazon Ember', sans-serif !important;
}

.font-nunito {
  font-family: 'Nunito', sans-serif !important;
}

.font-sixtyfour {
  font-family: 'Sixtyfour', sans-serif !important;
}

.pointer-events-none {
  user-select: none;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  user-select: none;
}

.ant-form-item-label {
  padding: 0 !important;
}

.btn .ant-space {
  width: 100% !important;
  justify-content: center !important;
}

.btn .ant-space-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.safari-backdrop-blur {
  backdrop-filter: blur(20px); /* Adjust the blur value as needed */
  -webkit-backdrop-filter: blur(20px); /* Safari support */
}
